@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "RalewayBlack";
  src: url("../public/fonts/Raleway-Black.ttf");
}
@font-face {
  font-family: "RalewayBold";
  src: url("../public/fonts/Raleway-Bold.ttf");
}
@font-face {
  font-family: "RalewayExtraBold";
  src: url("../public/fonts/Raleway-ExtraBold.ttf");
}
@font-face {
  font-family: "RalewayExtraLight";
  src: url("../public/fonts/Raleway-ExtraLight.ttf");
}
@font-face {
  font-family: "RalewayLight";
  src: url("../public/fonts/Raleway-Light.ttf");
}
@font-face {
  font-family: "RalewayMedium";
  src: url("../public/fonts/Raleway-Medium.ttf");
}
@font-face {
  font-family: "RalewayRegular";
  src: url("../public/fonts/Raleway-Regular.ttf");
}
@font-face {
  font-family: "RalewaySemiBold";
  src: url("../public/fonts/Raleway-SemiBold.ttf");
}
@font-face {
  font-family: "RalewayThin";
  src: url("../public/fonts/Raleway-Thin.ttf");
}
@font-face {
  font-family: "OpenSansBold";
  src: url("../public/fonts/OpenSans-Bold.ttf");
}
@font-face {
  font-family: "OpenSansBoldItalic";
  src: url("../public/fonts/OpenSans-BoldItalic.ttf");
}
@font-face {
  font-family: "OpenSansExtraBold";
  src: url("../public/fonts/OpenSans-ExtraBold.ttf");
}
@font-face {
  font-family: "OpenSansExtraBoldItalic";
  src: url("../public/fonts/OpenSans-ExtraBoldItalic.ttf");
}
@font-face {
  font-family: "OpenSansItalic";
  src: url("../public/fonts/OpenSans-Italic.ttf");
}
@font-face {
  font-family: "OpenSansLight";
  src: url("../public/fonts/OpenSans-Light.ttf");
}
@font-face {
  font-family: "OpenSansLightItalic";
  src: url("../public/fonts/OpenSans-LightItalic.ttf");
}
@font-face {
  font-family: "OpenSansMedium";
  src: url("../public/fonts/OpenSans-Medium.ttf");
}
@font-face {
  font-family: "OpenSansMediumItalic";
  src: url("../public/fonts/OpenSans-MediumItalic.ttf");
}
@font-face {
  font-family: "OpenSansRegular";
  src: url("../public/fonts/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "OpenSansSemiBold";
  src: url("../public/fonts/OpenSans-Bold.ttf");
}
@font-face {
  font-family: "OpenSansSemiBoldItalic";
  src: url("../public/fonts/OpenSans-SemiBoldItalic.ttf");
}
@font-face {
  font-family: "NotoSansBlack";
  src: url("../public/fonts/NotoSans-Black.ttf");
}
@font-face {
  font-family: "NotoSansBold";
  src: url("../public/fonts/NotoSans-Bold.ttf");
}
@font-face {
  font-family: "NotoSansExtraBold";
  src: url("../public/fonts/NotoSans-ExtraBold.ttf");
}
@font-face {
  font-family: "NotoSansMedium";
  src: url("../public/fonts/NotoSans-Medium.ttf");
}

.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}
@media (min-width: 769px) {
  .gallery {
    -webkit-column-count: 3;
    column-count: 3;
    -moz-column-count: 3;
    -webkit-column-width: 33%;
    column-width: 33%;
    -moz-column-width: 33%;
    padding: 0 3%;
  }
}

@media (max-width: 768px) {
  .gallery {
    -webkit-column-count: 2;
    column-count: 2;
    -moz-column-count: 2;
  }
}

@media (max-width: 480px) {
  .gallery {
    -webkit-column-count: 1;
    column-count: 1;
    -moz-column-count: 1;
  }
}
.play-button {
  transition: background-color 0.3s ease;
}

.play-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.play-icon {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
